<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">互动问答</div>
      <div class="bt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="returnbt">
        <div class="lefttitle">{{ iteminfo.name }}</div>
        <div class="bt" @click="release">新增问答</div>
      </div>
      <div class="bts">
        <div class="check-group">
          <div class="check-group-radio">
            <el-radio-group v-model="queryParams.type">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="1">仅教师参与</el-radio>
              <el-radio :label="2">回复最多</el-radio>
            </el-radio-group>
          </div>
          <div class="check-group-select">
            <el-select class="btleft" v-model="queryParams.belong_type" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-input class="elinput" v-model="queryParams.keyword" placeholder="请输入内容" clearable></el-input>
          </div>
          <div class="check-group-bt">
            <div class="bt restbt" @click="searchbt">查询</div>
            <div class="bt" @click="resetbt">重置</div>
          </div>
        </div>
      </div>
      <div class="itemcontain">
        <div class="item" v-for="(item, index) in qadata" :key="index">
          <!-- <div class="title">
            {{ item.title }}
            <span
              slot
              v-if="item.is_top === 1"
              class="item-two-bt item-two-bt-active"
            >置顶</span>
          </div>
          <div class="item-two">
            <div v-if="item.is_teacher_join === 1" class="item-two-bt">教师参与</div>
          </div>-->
          <div style="display:flex;align-items: center;">
            <div class="title" @click="toreply(item)">{{ item.title }}</div>
            <div class="item-two">
              <div v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">置顶</div>
              <div v-if="item.is_teacher_join === 1" class="item-two-bt">教师参与</div>
              <!-- <div v-if="item.has_publish === 1" class="item-two-bt item-two-bt-active">已发布</div> -->
              <div v-if="Number(item.has_publish) === 0" class="item-two-bt item-two-bt-active-1">待审核</div>
            </div>
          </div>
          <div class="text" @click="toreply(item)">
            <div v-html="item.content"></div>
          </div>
          <div class="bottom-contain">
            <div class="bottom-left">
              <el-image class="elimage" :src="item.avatar">
                <template slot="error">
                  <el-image :src="avater1"></el-image>
                </template>
              </el-image>
              <div class="teach">{{ item.author_name }}</div>
              <div class="time">{{ item.created_at | dataformat }}</div>
              <div class="time">发布</div>
            </div>
            <div class="bottom-right" v-if="Number(item.has_publish) === 1">
              <div class="bottom-right-item">
                <el-image class="elimage1 elimage-icons" :src="icon1" />
                <div class="num">{{ item.view_count }}</div>
              </div>
              <div class="line"></div>
              <div class="bottom-right-item" @click="setlikebt(item.id)">
                <template v-if="item.like_status == 0">
                  <el-image class="elimage2 elimage-icons" :src="icon2" />
                  <div class="num">{{ item.like_count }}</div>
                </template>
                <template v-else-if="item.like_status == 1">
                  <el-image class="elimage2 elimage-icons" :src="icon5" />
                  <div class="num">{{ item.like_count }}</div>
                </template>
              </div>

              <div class="line"></div>
              <div class="bottom-right-item" @click="toreply(item)">
                <el-image class="elimage3 elimage-icons" :src="icon3" />
                <div class="num">{{ item.reply_count }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
          :current-page="queryParams.page" @current-change="pageChange"></el-pagination>
      </div>
    </div>
    <Customdialog ref="customdialog" title="新增问答">
      <div slot="dialogbody" class="dialogbody">
        <div class="title">
          <span>标题：</span>
          <el-input class="elinput" v-model="title" placeholder="请输入标题" clearable></el-input>
        </div>
        <div>
          <div class="interactiveqa_content">
            <span>内容：</span>
          </div>
          <div class="interactiveqa_edit">
            <Tinymce ref="editor" v-model="content" style="height: auto; border-radius: 22px" @setInput="getValue">
            </Tinymce>
          </div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="canclebt">取消</div>
        <div class="bt btactive" @click="submitbt">确定</div>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import { autoImgCorrection } from "@/utils/index"
import { getQaList, setQaLike, getQaAdd } from "@/api/qa"
import dayjs from "dayjs"
import Tinymce from "@/components/VueTinymce/index.vue"
import Customdialog from "@/components/customdialog.vue"
export default {
  name: "mainpage",
  components: { Customdialog, Tinymce },
  data() {
    return {
      qa_id: "",
      title: "",
      content: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/查看_d.png"),
      icon5: require("@/assets/点赞_c.png"),
      icon2: require("@/assets/点赞_d.png"),
      icon3: require("@/assets/评论_d.png"),
      del_d: require("@/assets/删除_d.png"),
      avater1: require("@/assets/学生.png"),
      queryParams: {
        keyword: "",
        belong_type: 0,
        type: 0,
        related_type: 1,
        related_id: "",
        course_semester_sn: '',
        page: 1,
        per_page: 10
      },
      total: 0,
      options: [
        {
          value: 0,
          label: "全部讨论",
        },
        {
          value: 1,
          label: "我发表的问答",
        },
      ],
      qadata: [],
      iteminfo: {},
      loading: null,
    }
  },
  filters: {
    dataformat: function (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  computed: {},
  created() {
    //console.log(this.$route.query)
    this.iteminfo = this.$route.query
    this.qa_id = this.$route.query.course_semester_sn
    this.queryParams.course_semester_sn = this.qa_id
    this.getqalist()
  },
  methods: {
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 1,
          componentId: "Mycourses",
        },
      })
    },
    release() {
      this.$refs.customdialog.dialogopenbt()
      this.content = ""
      this.title = ""
    },
    toreply(item) {
      // let obj = JSON.stringify(item);
      // let obj2 = JSON.stringify(this.iteminfo);
      this.$router.push({
        path: "/home/personalcenter/courseinteractiveqadetails",
        query: {
          id: item.id,
          // item: encodeURIComponent(obj),
          // iteminfo: encodeURIComponent(obj2),
        },
      })
    },
    releasebt() {
      this.$refs.customdialog.dialogopenbt()
    },
    canclebt() {
      this.$refs.customdialog.dialogclosebt()
    },
    searchbt() {
      this.getqalist()
    },
    resetbt() {
      this.queryParams.keyword = ""
      this.queryParams.belong_type = 0
      this.queryParams.type = 0
      this.getqalist()
    },
    setlikebt(id) {
      this.loading = this.$loading({
        lock: true,
        text: "",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.4)",
        customClass: 'setlike-loading',
      })
      this.setQaLike({ id: id })
    },
    submitbt() {
      if (this.content === "") {
        this.canclebt()
        return false
      }
      this.QaAdd({
        title: this.title,
        related_type: 1,
        related_id: "",
        course_semester_sn: this.qa_id,
        content: this.content,
      })
    },
    QaAdd(params) {
      getQaAdd(params)
        .then((response) => {
          if (response.code === 0) {
            this.$refs.customdialog.dialogclosebt()
            this.loading = this.$loading({
              lock: true,
              text: "",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.4)",
              customClass: 'setlike-loading',
            })
            setTimeout(() => {
              this.getqalist()
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    async getqalist() {
      this.qadata = []
      await getQaList(this.queryParams)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            let resData = response.data.data
            if (resData && resData.length > 0) {
              resData.forEach(item => {
                item.content = autoImgCorrection(item.content)
              })
            }
            this.qadata = resData
            this.total = response.data.total
          }
          if (this.loading) {
            this.loading.close()
          }
        })
        .catch((error) => {
          if (this.loading) {
            this.loading.close()
          }
        })
    },
    pageChange(val) {
      this.queryParams.page = val
      this.getqalist()
    },
    setQaLike(params) {
      setQaLike(params)
        .then((response) => {
          if (response.code === 0) {
            setTimeout(() => {
              this.getqalist()
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    // 获取富文本得值
    getValue(data) {
      this.content = data
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    background-color: #fff;
    min-height: calc(100vh - 240px);
    padding-bottom: 20px;
    width: 100%;

    .head {
      margin-top: 66px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      padding-top: 10px;
      height: 40px;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }

      .elinput {
        width: 300px;

        ::v-deep .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        margin-right: 20px;
        font-size: 14px;
      }
    }

    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 2px solid #e8e8e8;
    }

    .maincontain {
      background: #ffffff;
      border-radius: 4px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;

      .returnbt {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 83px;
        padding: 0 20px;
        background: #f9f9f9;
        border-radius: 5px;

        .lefttitle {
          font-size: 16px;
          font-weight: 400;
          color: #222222;
        }

        .bt {
          width: 88px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background: #3d84ff;
          border-radius: 4px;
          color: white;
          user-select: none;
          cursor: pointer;
          font-size: 14px;
        }
      }

      .bts {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btleft {
          // margin-left: 20px;
        }

        .check-group {

          // display: flex;
          // justify-content: flex-start;
          // align-items: center;
          // height: 40px;
          .check-group-radio {
            margin-bottom: 20px;
          }

          .check-group-select {
            display: flex;
          }

          .check-group-bt {
            display: flex;
            margin-top: 20px;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
          }

          .check-all {
            margin-right: 20px;
          }

          .elinput {
            margin-left: 20px;
            width: 300px;

            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .bt {
            margin-left: 15px;
            width: 64px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #3d84ff;
            border-radius: 2px;
            user-select: none;
            cursor: pointer;
            font-size: 14px;
          }

          .restbt {
            background: #3d84ff;
            color: white;
          }
        }
      }

      .itemcontain {
        margin-top: 20px;
        background: #ffffff;

        .item {
          padding-top: 20px;
          // padding-left: 30px;
          margin-bottom: 10px;
          min-height: 140px;
          // background: #f7f7f7;
          border-bottom: 1px solid #cccccc;

          .title {
            font-size: 20px;
            font-weight: 400;
            color: #222222;
            cursor: pointer;
          }

          .item-two {
            // margin-top: 6px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .item-two-bt {
              width: 60px;
              min-width: 60px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              padding: 0 2px;
              font-family: PingFang SC;
              font-size: 12px;
              font-weight: 400;
              color: #409eff;
              background: #ffffff;
              border: 1px solid #409eff;
              border-radius: 4px;
              margin-top: 3px;
              margin-left: 10px;
            }

            .item-two-bt-active {
              color: #0fbf7f;
              width: 40px;
              min-width: 40px;
              border: 1px solid #0fbf7f;
            }

            .item-two-bt-active-1 {
              color: #f13218;
              width: 40px;
              min-width: 40px;
              border: 1px solid #f13218;
            }
          }

          .text {
            margin-top: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
            cursor: pointer;
          }

          .bottom-contain {
            margin-top: 20px;
            margin-bottom: 20px;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            .bottom-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 10px;

              .elimage {
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }

              .teach {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }

              .time {
                margin-right: 10px;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #a0a0a0;
              }
            }

            .bottom-right {
              // margin-bottom: 20px;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .bottom-right-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;

                .elimage1 {
                  width: 22px;
                  height: 16px;
                }

                .elimage2 {
                  width: 19px;
                  height: 21px;
                  margin-bottom: 4px;
                }

                .elimage3 {
                  width: 19px;
                  height: 16px;
                }

                .num {
                  margin-left: 6px;
                  font-size: 15px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #a0a0a0;
                }
              }

              .line {
                margin-left: 20px;
                margin-right: 20px;
                height: 15px;
                margin-top: 5px;
                border-right: 2px solid #9b9b9b;
              }
            }
          }
        }
      }
    }

    .releasebt {
      margin-top: 80px;
      margin-right: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        user-select: none;
        cursor: pointer;
        color: white;
      }
    }

    .dialogbody {
      padding: 20px;
      overflow: hidden;

      .elinput {
        margin-bottom: 10px;
        width: 82%;
        padding-left: 18px;
      }

      .interactiveqa_content {
        width: 60px;
        float: left;
      }

      .interactiveqa_edit {
        width: calc(100% - 60px);
        float: left;
      }
    }

    ::v-deep .el-dialog {
      width: 100%;
    }

    .dialogfooter {
      padding-right: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bt {
        margin-right: 20px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 400;
        color: #a0a0a0;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }

      .btactive {
        background: #3d84ff;
        color: white;
        border: 1px solid #3d84ff;
      }
    }
  }

  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    padding-right: 20px;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }

  .elimage-icons {
    width: 20px !important;
    height: 20px !important;
  }
}

@media screen and (min-width: 1200px) {
  .mainpage {
    background-color: #fff;
    min-height: calc(100vh - 240px);
    padding-bottom: 20px;
    width: 1440px;

    .head {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      padding-top: 10px;
      height: 40px;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }

      .elinput {
        width: 300px;

        ::v-deep .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        margin-right: 20px;
        font-size: 14px;
      }
    }

    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 2px solid #e8e8e8;
    }

    .maincontain {
      background: #ffffff;
      border-radius: 4px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;

      .returnbt {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 83px;
        padding: 0 20px;
        background: #f9f9f9;
        border-radius: 5px;

        .lefttitle {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }

        .bt {
          width: 88px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background: #3d84ff;
          border-radius: 4px;
          color: white;
          user-select: none;
          cursor: pointer;
          font-size: 14px;
        }
      }

      .bts {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btleft {
          margin-left: 20px;
        }

        .check-group {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .check-all {
            margin-right: 20px;
          }

          .check-group-bt {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
          }

          .elinput {
            margin-left: 20px;
            width: 300px;

            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .bt {
            margin-left: 15px;
            width: 64px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #3d84ff;
            border-radius: 2px;
            user-select: none;
            cursor: pointer;
            font-size: 14px;
          }

          .restbt {
            background: #3d84ff;
            color: white;
          }
        }
      }

      .itemcontain {
        margin-top: 20px;
        background: #ffffff;

        .item {
          padding-top: 20px;
          padding-left: 30px;
          margin-bottom: 10px;
          min-height: 140px;
          // background: #f7f7f7;
          border-bottom: 1px solid #cccccc;

          .title {
            font-size: 20px;
            font-weight: 400;
            color: #222222;
            cursor: pointer;
          }

          .item-two {
            // margin-top: 6px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .item-two-bt {
              width: 60px;
              min-width: 60px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              padding: 0 2px;
              font-family: PingFang SC;
              font-size: 12px;
              font-weight: 400;
              color: #409eff;
              background: #ffffff;
              border: 1px solid #409eff;
              border-radius: 4px;
              margin-top: 3px;
              margin-left: 10px;
            }

            .item-two-bt-active {
              color: #0fbf7f;
              width: 40px;
              min-width: 40px;
              border: 1px solid #0fbf7f;
            }

            .item-two-bt-active-1 {
              color: #f13218;
              width: 40px;
              min-width: 40px;
              border: 1px solid #f13218;
            }
          }

          .text {
            margin-top: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
            cursor: pointer;
          }

          .bottom-contain {
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .bottom-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .elimage {
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }

              .teach {
                margin-left: 10px;
                margin-right: 10px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }

              .time {
                margin-right: 10px;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #a0a0a0;
              }
            }

            .bottom-right {
              // margin-bottom: 20px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .bottom-right-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;

                .elimage1 {
                  width: 22px;
                  height: 16px;
                }

                .elimage2 {
                  width: 19px;
                  height: 21px;
                  margin-bottom: 4px;
                }

                .elimage3 {
                  width: 19px;
                  height: 16px;
                }

                .num {
                  margin-left: 6px;
                  font-size: 15px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #a0a0a0;
                }
              }

              .line {
                margin-left: 20px;
                margin-right: 20px;
                height: 15px;
                margin-top: 5px;
                border-right: 2px solid #9b9b9b;
              }
            }
          }
        }
      }
    }

    .releasebt {
      margin-top: 80px;
      margin-right: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        user-select: none;
        cursor: pointer;
        color: white;
      }
    }

    .dialogbody {
      padding: 20px;
      overflow: hidden;

      .elinput {
        margin-bottom: 10px;
        width: 40%;
        padding-left: 18px;
      }

      .interactiveqa_content {
        width: 60px;
        float: left;
      }

      .interactiveqa_edit {
        width: calc(100% - 100px);
        float: left;
      }
    }

    .dialogfooter {
      padding-right: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bt {
        margin-right: 20px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 18px;
        font-weight: 400;
        color: #a0a0a0;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }

      .btactive {
        background: #3d84ff;
        color: white;
        border: 1px solid #3d84ff;
      }
    }
  }

  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    padding-right: 20px;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }

  .elimage-icons {
    width: 20px !important;
    height: 20px !important;
  }
}
</style>
